import { useState, useEffect } from "react";
import React from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import CloseIcon from '@mui/icons-material/Close';
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { Paginated } from "../../components/Paginated";
import '../../components/table.css';
import styles from "./transaction.module.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { toast } from "react-toastify";
import "../../index.css";
import * as XLSX from 'xlsx';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import TransactionTemplate from "../../components/TransactionTemplate/TransactionTemplate";
const { apiEndpoint } = config;

var $ = require('jquery');
const badgeMaker: any = {
    "Created": (order_state: any) => <span className="badge badge-primary">{order_state}</span>,
    "Shipped": (order_state: any) => <span className="badge badge-warning">{order_state}</span>,
    "Delivered": (order_state: any) => <span className="badge badge-success">{order_state}</span>,
    "Canceled": (order_state: any) => <span className="badge badge-danger">{order_state}</span>,
}


const Mstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    '@media (max-width: 600px)': {
        width: '100%',
    },
};

const Transction = () => {
    const decoded: any = tokenDecode();
    const [transactionList, setTransactionList] = useState([]);
    const [stransactionList, setStransactionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDetailsLoading, setIsDetailsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [Fopen, setFOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleFOpen = () => setFOpen(true);
    const handleFClose = () => setFOpen(false);
    const [Msgopen, setMsgOpen] = useState(false);
    const [tcsValue, settcsValue] = useState(0);
    const handleClose = () => {
        setOpen(false); setCalculation({
            "order_id": "",
            "sellerapp_commission": "",
            "ondc_commission": "",
            "buyer_commission": "",
            "seller_amount": "",
            "order_total": "",
            "tds_deduction": "",
            "tcs_deduction": "",
            "receive_status": 0,
            "settlement_status": "",
            "buyer_commission_percentage": "",
            "gst_on_buyer_commission": "",
            "ONDC_Commissions": "",
            "seller_np_amount": "",
            "seller_np_amount_gst": ""
        })
        settdsValue(0);
        settcsValue(0);
        setsettleClicked(false);
        setTransactionid("");
        setTransactionidDate("");
        setalltransactions([]);
        allTransactionlist();
    };
    const [calculation, setCalculation] = useState({
        "order_id": "",
        "sellerapp_commission": "",
        "ondc_commission": "",
        "buyer_commission": "",
        "seller_amount": "",
        "order_total": "",
        "tds_deduction": "",
        "tcs_deduction": "",
        "receive_status": 0,
        "settlement_status": "",
        "buyer_commission_percentage": "",
        "gst_on_buyer_commission": "",
        "ONDC_Commissions": "",
        "seller_np_amount": "",
        "seller_np_amount_gst": ""
    })
    const [SelectionParam, setSelectionParam]: any = useState(false)
    const [Transactionid, setTransactionid]: any = useState("");
    const { data } = decoded;
    const rawData = {
        seller_id: ''
    }
    const [searchData, setSearchData]: any = useState(rawData);
    const { permission: { seller, } } = data;
    const [sellerList, setSellerList] = useState([]);
    const [valueid, setValueid] = useState("");
    const [valueamount, setValueamount] = useState("");
    const [valuedate, setValuedate] = useState("");
    const [alltransactionData, setalltransactionData] = useState([]);
    const [alltransactions, setalltransactions] = useState([])
    const [TransactionidDate, setTransactionidDate]: any = useState("");
    const [settleClicked, setsettleClicked] = useState(false);
    const [tdsValue, settdsValue] = useState(0);
    var count = 1;
    const [selectedBox, setselectedBox]: any = useState([]);
    const [filterDaata, setfilterDaata] = useState({
        seller_id: "",
        startDate: "",
        endDate: ""
    })
    interface State extends SnackbarOrigin {
        sopen: boolean;
    }
    const [state, setState] = useState<State>({
        sopen: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, sopen } = state;
    const handleClick = (newState: SnackbarOrigin) => {

        setState({ ...newState, sopen: true });
    };
    useEffect(() => {
        // console.log(selectedBox)
        if (selectedBox.length > 0) {
            handleClick({ vertical: 'top', horizontal: 'center' })
        }
    }, [selectedBox])

    const fetchSellerList = async () => {
        let apiendpoint;
        if (decoded?.data?.user?.seller_id === 1) {
            apiendpoint = "transactionStatus"
        } else {
            apiendpoint = "transactionStatus"
        }
        setIsLoading(true);
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/${apiendpoint}`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    settlement_status: 1
                }
            });
            // console.log("transaction data -> ", data);
            if (data.status === 200) {
                setIsLoading(false);
                setTransactionList(data.data);
                setalltransactionData(data.data)

            }
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
        }
    }
    const getTransaction = async (order_id: any) => {
        try {
            setIsDetailsLoading(true)
            setCalculation({ ...calculation, order_id });
            const { data } = await axios({
                url: `${apiEndpoint}/getcalculationNew`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    order_id
                }
            });
            // console.log("calculation detail ->------   ", data);
            // console.log("okss", data.data.seller_amount.toFixed(2));

            setCalculation({
                order_id,
                "sellerapp_commission": parseFloat(data.data[0].sellerapp_commission).toFixed(2),
                "ondc_commission": parseFloat(data.data[0].ondc_commission).toFixed(2),
                "buyer_commission": parseFloat(data.data[0].buyer_commission).toFixed(2),
                "seller_amount": parseFloat(data.data[0].seller_amount).toFixed(2),
                "order_total": parseInt(data.data[0].order_total).toFixed(2),
                "tds_deduction": parseInt(data.data[0].tcs_deduction).toFixed(2),
                "tcs_deduction": parseInt(data.data[0].tds_deduction).toFixed(2),
                "receive_status": data.data[0].receive_status,
                "settlement_status": data.data[0].settlement_status,
                "buyer_commission_percentage": data.data[0].buyer_commission_percentage,
                "gst_on_buyer_commission": data.data[0].gst_on_buyer_commission,
                "ONDC_Commissions": data.data[0].ONDC_Commissions,
                "seller_np_amount": data.data[0].seller_np_amount,
                "seller_np_amount_gst": data.data[0].seller_np_amount_gst

            });

            settcsValue(parseInt(data.data[0].tcs_deduction))
            settdsValue(parseInt(data.data[0].tds_deduction))
            setIsDetailsLoading(false)
        } catch (error) {
            toast.error("Something Went Wrong!")
            setIsDetailsLoading(false)
        }
    }
    const getSellerList = async () => {
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerlist`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                }
            });
            if (data.status === 200) {
                // console.log(data.data)
                setSellerList(data.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const inputHandler = async (e: any) => {
        setValueid("");
        setValueamount("");
        setValuedate("");
        var optionvalue = e.target.value;
        if (optionvalue) {
            try {

                const {
                    seller_id
                } = searchData;
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filtertransactiondataall`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                    },
                    data: {
                        seller_id: optionvalue
                    }

                });
                if (data.status === 200) {
                    // console.log(data.response);
                    setTransactionList(data.response);
                    setStransactionList(data.response);
                }


            } catch (error) {
                console.log("error", error);
            }
        }
        else {
            setTransactionList(stransactionList);
        }
    };

    const inputeHandler = async (e: any) => {

        const optionvalue = e.target.value;
        setValueid(optionvalue);
        if (optionvalue) {
            try {
                const {
                    seller_id
                } = searchData;
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filtertransactionsellerdata`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                    },
                    data: {
                        seller_id: { searchData },
                        order_id: optionvalue
                    }

                });

                if (data.status === 200) {
                    // console.log(data);
                    setTransactionList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };


    const handlesClose = () => {
        setState({ ...state, sopen: false });
    };

    function checkHandler(row: any) {
        // console.log("row---->", row)
        // console.log("dhadha", row.order_id);
        let hasId = selectedBox.some((item: any) => item == row.order_id)
        if (!hasId) {
            setselectedBox((prev: any) => {
                return [...prev, row.order_id]
            })
        } else {
            setselectedBox((prev: any) => {
                return prev.filter((item: any) => item !== row.order_id)
            })
        }

    }

    // console.log("selectedBox----->", selectedBox)
    const COLUMNS = [
        !SelectionParam ? {
            Header: "Serial No.",
            Footer: "Serial No.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        } : {
            Header: "Select",
            Footer: "Select",
            accessor: (row: any) => {
                return <input type="checkbox" onChange={() => checkHandler(row)} checked={selectedBox.includes(row.order_id)} />

            },
            disableFilters: true,
            sticky: "left",

        },
        {
            Header: "Order ID",
            Footer: "Order ID",
            accessor: "order_id",
            sticky: "left"
        },
        {
            Header: "Seller Name",
            Footer: "Seller Name",
            accessor: "company_name",
            sticky: "left"
        },
        {
            Header: "Total Amount (in Rs)",
            Footer: "Total Amount (in Rs)",
            accessor: (a: any) => parseInt(a.order_total).toFixed(2),
            sticky: "left"
        },
        {
            Header: "Buyer Commission (in Rs)",
            Footer: "Buyer Commission (in Rs)",
            accessor: (a: any) =>  {let roundedNumber = Math.round(parseFloat(a?.buyer_amt) * 1000) / 1000; return roundedNumber},
            sticky: "left"
        },
        {
            id: "settlestatus",
            Header: "Settle Status",
            Footer: "Settle Status",
            accessor: (a: any) => a.settlement_status == "1" ? "Settled" : "Not Settled",
            sticky: "left"
        },
        {
            id: "Transaction id",
            Header: "Transaction id",
            Footer: "Transaction id",
            accessor: (a: any) => a.transaction_id,
            sticky: "left"
        },
        {
            Header: "Created Date",
            Footer: "Created Date",
            accessor: "created_at",
            sticky: "left"
        },
        {
            Header: "Action",
            Footer: "Action",
            accessor: (row: any) => {
                return <><button className="btn btn-warning"
                    onClick={() => { getTransaction(row.order_id); handleOpen() }}
                >
                    <i className="fas fa-eye"></i>
                </button></>
            },
            sticky: "left"
        },

    ];
    count++

    useEffect(() => {
        getSellerList();
        allTransactionlist();
        // if (decoded?.data?.user?.seller_id <= 1 && decoded?.data?.user?.parent_id <= 1) {
        //     setMsgOpen(true)
        // }

        if(decoded?.data?.user?.seller_id === 1 ){
            getallsettledtransactions()
        }

        if(decoded?.data?.user?.seller_id > 1){
            fetchSellerList()
        }

    }, [])

    async function getallsettledtransactions() {
        setIsLoading(true);
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/get_transactions_list_settled`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    settlement_status: 0
                }
            });
            // console.log("transaction data -> ", data);
            if (data.status === 200) {
                setTransactionList(data?.data);
                setStransactionList(data?.data);
                setalltransactionData(data?.data);
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error", error);

            setIsLoading(false);
        }
    }


    function OrderidSearchHandler(e: any) {
        if (e.target.value == "") {
            if (decoded?.data?.user?.seller_id <= 1 && decoded?.data?.user?.parent_id <= 1) {
               // getFilteredData()
               getallsettledtransactions();
               setfilterDaata((prev) =>{
                   return  {...prev, seller_id: ""}
               })
            } else {
                fetchSellerList()
            }

        } else {
            setTransactionList(alltransactionData.filter((item: any) => item.order_id.includes(e.target.value)))
        }

    }

    // console.log("alltransactionData", alltransactionData)

    function filterChangeHandler(e: any) {
        setfilterDaata((prev) => {
            return {
                ...prev, [e.target.name]: e.target.value
            }
        })
    }

    async function getFilteredData() {

        try {
            const { data } = await axios({
                url: `${apiEndpoint}/filtertransactiondataall`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    ...filterDaata
                }

            });
            if (data.status === 200) {
                // console.log(data.response);
                setTransactionList(data.response);
                setStransactionList(data.response);
                setalltransactionData(data.response);
            }

            handleFClose()


        } catch (error) {
            handleFClose()
            console.log("error", error);
        }

    }

    const allTransactionlist = async () => {
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/exporttransaction`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    seller_id: decoded?.data?.user?.seller_id,
                }
            });
            // console.log("order data -> ", data);
            if (data.status === 200) {

                setalltransactions(data.data)
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const exportToExcel = (orders: any) => {

        const headers = [
            'S.No', "Order Id", "Seller Name", "Order Total", "Seller Amount", "Buyer Commission", "Settlement", "TDS", "TCS", "Settled Date", "Order Date"
        ];

        const data = orders.map((order: any, index: any) => {

            // let orderCreatedDate = moment(order?.created_at).format("DD-MM-YYYY hh:mm:ss")
            // let sellerName = order?.address?.start?.location?.descriptor?.name
            // let sellerEmail = order?.address?.end?.contact?.email
            // let sellerContact = order?.address?.end?.contact?.phone
            return {
                'S.No': index + 1,
                "Order Id": order?.order_id,
                "Seller Name": order.company_name,
                "Order Total": order?.order_total,
                "Seller Amount": order?.seller_amount,
                "Buyer Commission": order?.buyer_amt,
                "Settlement": order?.settlement,
                "TDS": order?.tds,
                "TCS": order?.tcs,
                "Settled Date": order?.settled_date,
                "Order Date": order?.order_date
            }
        });

        // // Create a new workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data, { header: headers });

        // // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Orders');

        // // Generate a unique name for the Excel file (you can customize this as needed)
        const fileName = `transactions_${new Date().getTime()}.xlsx`;

        // // Export the workbook as an Excel file
        XLSX.writeFile(wb, fileName);
    };

    async function clearSelectionHandler() {
        setSelectionParam(false)
        setselectedBox([])
        handlesClose()

    }

    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month: string | number = today.getMonth() + 1;
        let day: string | number = today.getDate();

        // Add leading zero if month or day is a single digit
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;

        return `${year}-${month}-${day}`;
    }

    async function submitCheckedDataSingle() {

        try {
            const { data } = await axios({
                url: `${apiEndpoint}/sss`,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                method: "POST",
                data: {
                    order_id: calculation?.order_id,
                    transaction_id: Transactionid,
                    settlement_status: 1,
                    settlement_date: TransactionidDate
                },
            })

            if (data.status == 200) {

                if (data.status === 200) {
                    toast.success("Settled Successfully!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    clearSelectionHandler()
                }
            } else {
                toast.error("Error in Setteling!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error("Something Went Wrong!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }

    }

    const action = (
        <div className="d-flex justify-content-between align-items-center" style={{ gap: "10px" }}>

            <input type="text" className="form-control" placeholder="Transaction ID.." onChange={(e) => { setTransactionid(e.target.value) }} />


            <input type="date" name="" id="" className="form-control bg-white" max={getCurrentDate()} onChange={(e) => { setTransactionidDate(e.target.value) }} />
            <button className=" ml-5 w-50 text-center text-dark mb-1  p-1 " style={{ color: "white", background: "lightgrey", borderRadius: "20px", height: "30px", cursor: "pointer", fontWeight: "700", border: "none" }} disabled={((Transactionid === "") || (TransactionidDate === "")) ? true : false}
                onClick={submitCheckedDatatoOn}
            >
                Settle
            </button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={clearSelectionHandler}
                style={{ marginLeft: "20px" }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </div>
    );

    async function submitCheckedDatatoOn() {

        try {
            const { data } = await axios({
                url: `${apiEndpoint}/update_settlementssss`,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                method: "POST",
                data: {
                    order_id: [...selectedBox],
                    transaction_id: Transactionid,
                    settlement_status: 1,
                    settlement_date: TransactionidDate
                },
            })

            if (data.status == 200) {

                if (data.status === 200) {
                    toast.success("Settled Successfully!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    clearSelectionHandler()
                }
            } else {
                toast.error("Error in Setteling!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error("Something Went Wrong!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }

    }

    function inactiveProductHandler() {
        // console.log(productList)
        let z = transactionList.filter(item => item)
        // console.log(z);
        let m = z.map((item: any) => item?.order_id)
        setselectedBox(m)
        // checkHandler(...m)
    }

    const preventMinus = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    async function receiveHandler() {

        try {
            const { data } = await axios({
                url: `${apiEndpoint}/sss`,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                method: "POST",
                data: {
                    order_id: calculation?.order_id,
                    tds: tdsValue,
                    status: 1
                },
            })

            if (data.status == 200) {

                if (data.status === 200) {
                    toast.success("Received Successfully!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    clearSelectionHandler()
                }
            } else {
                toast.error("Error in Receiving!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error("Something Went Wrong!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }

    }

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleMsgClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setMsgOpen(false);
    };

    async function selectSellerHandler(e: any) {
        setfilterDaata((prev) => {
            return { ...prev, seller_id: e.target.value }
        })
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/filtertransactiondataall`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    seller_id: e.target.value
                }

            });
            if (data.status === 200) {
                // console.log(data.response);
                setTransactionList(data.response);
                setStransactionList(data.response);
                setalltransactionData(data.response);
            }

            handleFClose()


        } catch (error) {
            handleFClose()
            console.log("error", error);
        }
    }


    function calculateGST(originalValue: any, gstRate: number) {
        let z = parseInt(originalValue)
        return (z * gstRate) / 100;
    }
    // console.log("selectedBox------>", selectedBox)


    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <div className={`d-flex justify-content-between p-2 align-items-center ${styles.headerContainer}`}> <h6 className="m-0 font-weight-bold text-primary text-left">
                                   Settled Transaction list
                                </h6>

                                    <div className="search mt-1 widthControl">
                                        <div className="row m-0 d-flex justify-content-center">
                                            <div className="d-flex flex-column flex-md-row w-100" style={{ gap: "8px" }}>
                                                <div>
                                                    <input type="search" name="order_id" placeholder="Enter Order ID" className="mr-2 form-control" onChange={(e: any) => OrderidSearchHandler(e)}
                                                    />
                                                </div>

                                                <div>
                                                    {(decoded?.data?.user?.seller_id <= 1 && decoded?.data?.user?.parent_id <= 1) ? <div className={styles.selectionControl}>
                                                        <select name="seller_id" id=""
                                                            // onChange={(e: any) => inputHandler(e)}
                                                            onChange={(e) => { selectSellerHandler(e) }}
                                                            className="form-control selectboxwidth"

                                                            value={filterDaata.seller_id}


                                                        >
                                                            <option value="" disabled>Select Seller</option>
                                                            {
                                                                sellerList.map((item: any) => (
                                                                    <option value={item?.seller_id} key={item?.seller_id}>{item?.company_name}</option>
                                                                ))
                                                            }

                                                        </select>
                                                    </div> : null}
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center ">
                                                    {(decoded?.data?.user?.seller_id <= 1 && decoded?.data?.user?.parent_id <= 1) ? <button className="btn btn-primary " onClick={handleFOpen}>Filters</button> : null}

                                                    <button className="btn btn-primary ml-2" onClick={() => setTimeout(() => {
                                                        exportToExcel(alltransactions)
                                                    }, 2000)} disabled={alltransactions.length === 0 ? true : false}>Export Transactions</button>
                                                </div>
                                            </div>


                                        </div>
                                    </div>



                                </div>

                            </div>

                            <div className="card-body p-0" style={{ padding: "10px !important" }}>
                                <div className="table-responsive">
                                    <div className="d-flex justify-content-end">
                                        {SelectionParam ? <div className="d-flex align-items-center"> <span onClick={clearSelectionHandler} className="text-center m-2" style={{ background: "lightgrey", borderRadius: "12px", fontSize: "12px", width: "70px", height: "25px", cursor: "pointer", paddingTop: "3px", fontWeight: "600" }}>Cancel</span>
                                            <span onClick={inactiveProductHandler} className="text-center  my-2 mx-2" style={{ background: "lightgrey", borderRadius: "12px", fontSize: "12px", width: "70px", height: "25px", cursor: "pointer", paddingTop: "3px", fontWeight: "600" }}>Select All</span> </div> :
                                            <span onClick={() => setSelectionParam(true)} className="text-center d-none text-dark rounded my-2 mx-2" style={{ background: "lightgrey", fontSize: "14px", width: "80px", cursor: "pointer", paddingTop: "3px", fontWeight: "600", }}>Edit</span>}
                                    </div>

                                    <div className="productListing_desktop">
                                        {!isLoading ? <Paginated data={transactionList} columns={COLUMNS} /> : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div>}
                                    </div>

                                    <div className={styles.transactionListing_mobile}>
                                        {/* <TransactionTemplate transactionList={transactionList} getTransaction={getTransaction} calculation={calculation} ></TransactionTemplate> */}
                                        {transactionList.map((item: any) => {

                                            return <div className="border p-1 bg-white mt-2">
                                                <div className="d-flex  mt-1 align-items-center" style={{ gap: "10px" }}>
                                                    <span className="genericlabel" style={{ width: "28%" }} >Order ID</span>
                                                    <span className="" style={{ fontSize: "14px", width: '70%' }}>{item?.order_id}</span>
                                                </div>
                                                <div className="d-flex  mt-1 align-items-center" style={{ gap: "10px" }}>
                                                    <span className="genericlabel" style={{ width: "28%" }} >Transaction ID</span>
                                                    <span className="" style={{ fontSize: "14px", width: '70%' }}>{item?.transaction_id}</span>
                                                </div>
                                                <div className="d-flex  mt-1" style={{ gap: "10px" }}>
                                                    <span className="genericlabel " style={{ width: "28%" }} >Order Total</span>
                                                    <span className="" style={{ fontSize: "14px", width: '70%' }}>{item?.order_total}</span>
                                                </div>
                                                <div className="d-flex mt-1" style={{ gap: "10px" }}>
                                                    <span className="genericlabel " style={{ width: "28%" }} > Seller Name</span>
                                                    <span className="" style={{ fontSize: "14px", width: '70%' }}>{item?.company_name}</span>
                                                </div>
                                                {/* <div className="d-flex mt-1" style={{ gap: "10px" }}>
        <span className="genericlabel " style={{ width: "31%" }}>Settlement Status</span>
        <span className="" style={{ fontSize: "14px", width: '69%' }}>Settled</span>
    </div> */}
                                                <div className="mt-2">
                                                    <p style={{ width: "90px", fontWeight: 600, borderRadius: "17px", color: "white", background: "blue", padding: "4px", textAlign: "center" }}
                                                        onClick={() => { getTransaction(item.order_id); handleOpen() }} >View</p>

                                                </div>
                                            </div>

                                        })

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={Mstyle}>
                        <div className="modal-header position-relative">
                            <p className="modal-title" style={{ fontSize: "14px", fontWeight: "500", color: "black" }}>Order id: {calculation?.order_id} </p>
                            <button type="button" className="close position-absolute" style={{ right: "0", top: "0" }} onClick={() => handleClose()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {/* <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>  Buyer Commission : {calculation?.buyer_commission} </p>
                                </div>
                                <div className="col-md-12">
                                    <p>  ONDC Commission :{calculation?.ondc_commission} </p>
                                </div>
                                <div className="col-md-12">
                                    <p>  Seller App Commission : {calculation?.sellerapp_commission} </p>
                                </div>
                                <div className="col-md-12">
                                    <p>  Seller Amount : {(calculation?.seller_amount)} </p>
                                </div>
                                <div className="col-md-12">
                                    <p>  Total Amount : {calculation?.order_total} </p>
                                </div>
                            </div>
                        </div> */}
                        {!isDetailsLoading ? <TableContainer component={Paper}>
                            <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                                <TableHead>

                                    <TableRow >
                                        <TableCell align="center" className="bg-light">Order Amount</TableCell>

                                        <TableCell align="center" className="bg-light font-weight-bold" >
                                            <span>&#8377;</span>  {calculation?.order_total}
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>

                                        <TableCell align="center" className="bg-light">TDS Deduction</TableCell>
                                        {/* <TableCell align="center" className="bg-light font-weight-bold">{calculation.tds_deduction}</TableCell> */}

                                        <TableCell align="center" className="bg-light font-weight-bold">
                                            <input type="number" className="form-control" min={0} value={tdsValue} onKeyPress={preventMinus} onChange={(e: any) => settdsValue(e.target.value)} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" className="bg-light">TCS Deduction</TableCell>
                                        {/* <TableCell align="center" className="bg-light font-weight-bold">{calculation.tcs_deduction}</TableCell> */}
                                        <TableCell align="center" className="bg-light font-weight-bold">
                                            <input type="number" className="form-control" min={0} value={tcsValue} onKeyPress={preventMinus} onChange={(e: any) => settcsValue(e.target.value)} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow> <TableCell align="center" className="bg-light">Buyer Commission ({calculation.buyer_commission_percentage}%)</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold"> <span>&#8377;</span>  {calculation.buyer_commission}</TableCell>
                                    </TableRow>
                                    <TableRow> <TableCell align="center" className="bg-light">GST On Buyer Commission (18%)</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold"> <span>&#8377;</span>  {(Math.round(parseFloat(calculation.gst_on_buyer_commission) * 100) / 100).toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow> <TableCell align="center" className="bg-light">Seller NP Commission ({calculation.ONDC_Commissions}%)</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold"> <span>&#8377;</span> {(Math.round(parseFloat(calculation.seller_np_amount) * 100) / 100).toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow> <TableCell align="center" className="bg-light">GST on Seller NP amount</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold"> <span>&#8377;</span>{(Math.round(parseFloat(calculation.seller_np_amount_gst) * 100) / 100).toFixed(2)}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center" className="bg-light">Seller Amount</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold" > <span>&#8377;</span>  {calculation.seller_amount}</TableCell>
                                    </TableRow>





                                </TableHead>

                            </Table>

                            <div className="d-flex justify-content-end p-2 w-100" style={{ gap: "13px" }}>
                                {!settleClicked ? <>{calculation.receive_status !== 1 && <button className="btn btn-secondary" onClick={receiveHandler} >Receive</button>}
                                    {calculation.settlement_status != "1" ? <button className="btn btn-success" onClick={() => {
                                        setsettleClicked(true)
                                    }} >Settle</button> : null} </> : null}
                                {
                                    settleClicked ? <div className="d-flex justify-content-center" style={{ gap: "20px" }}>
                                        <input type="text" className="form-control" placeholder="Transaction ID.." onChange={(e) => { setTransactionid(e.target.value) }} />
                                        <input type="date" name="" id="" className="form-control bg-white" max={getCurrentDate()} onChange={(e) => { setTransactionidDate(e.target.value) }} />
                                        <button className="btn btn-primary" disabled={((Transactionid === "") || (TransactionidDate === "")) ? true : false} onClick={submitCheckedDataSingle}>Submit</button>
                                    </div> : null
                                }
                            </div>

                        </TableContainer> : null
                        }
                        {
                            isDetailsLoading ? <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div> : null
                        }
                    </Box>
                </Modal>
                <Modal open={Fopen}
                    onClose={handleFClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">


                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" style={{ minHeight: "200px" }}>
                            <div className="modal-header d-flex justify-content-between">

                                <h5 className="modal-title text-dark genericlabel" id="exampleModalLongTitle"> Filters  </h5>

                                <span aria-hidden="true" onClick={handleFClose} style={{ cursor: "pointer" }} ><CloseIcon /></span>

                            </div>


                            <div className="modal-body w-50" >


                                {(decoded?.data?.user?.seller_id === 1 || decoded?.data?.user?.parent_id === 1) ?
                                    <div > <div className={styles.selectionControl}>
                                        <select name="seller_id" id=""
                                            // onChange={(e: any) => inputHandler(e)}
                                            onChange={filterChangeHandler}
                                            className="form-control mb-3"
                                            value={filterDaata.seller_id}

                                        >
                                            <option value="">Select Seller</option>
                                            {
                                                sellerList.map((item: any) => (
                                                    <option value={item?.seller_id} key={item?.seller_id}>{item?.name}</option>
                                                ))
                                            }

                                        </select>
                                    </div>
                                    </div>
                                    : ""}

                                <div className="d-flex " style={{ gap: "20px" }}>

                                    <div>
                                        <label >Start Date</label>

                                        <input name="startDate" type="date" className="form-control" onChange={filterChangeHandler} value={filterDaata.startDate} max="{{ getCurrentDate() }}"
                                        />
                                    </div>

                                    <div>
                                        <label className="text-dark" >End Date</label>
                                        <input name="endDate" type="date" onChange={filterChangeHandler} className="form-control " value={filterDaata.endDate} max="{{ getCurrentDate() }}"
                                        />
                                    </div>

                                </div>

                            </div>

                            <div className="modal-header d-flex justify-content-end " style={{ gap: "20px" }}>

                                <button className="btn btn-danger" data-dismiss="modal" aria-label="Close" onClick={() => {
                                    window.location.reload()
                                    // handleFClose();
                                    // fetchSellerList();
                                    // setfilterDaata({
                                    //     seller_id: "",
                                    //     startDate: "",
                                    //     endDate: ""
                                    // })


                                }} >Clear Filter</button>

                                <button className="btn btn-primary" data-dismiss="modal" aria-label="Close"
                                    onClick={getFilteredData}
                                >Apply Filter</button>
                            </div>


                        </div>
                    </div>


                </Modal >

            </ThemeMaker >

            <div style={{ width: "25%" }}>
                {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={sopen}
                    sx={{ width: "100%" }}
                    // onClose={handleClose}
                    key={vertical + horizontal}
                    action={action}
                />
            </div>

            <Snackbar open={Msgopen} autoHideDuration={20000} onClose={handleMsgClose} key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={handleMsgClose} sx={{ width: '100%', padding: "30px", fontWeight: 600, background: "#105293", fontSize: "18px" }} >
                    To see transactions, Select a seller.
                </Alert>
            </Snackbar>
        </>
    )
}


export default Transction;
